import {
  Container,
  List,
  ListItem,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../../Components/Footer/Footer";
import { faqQnA } from "../../Service/FaqData";

const FAQMorePage = () => {
  return (
    <div>
      <Container
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          pb: 5,
        }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ paddingTop: 5, mb: 1, letterSpacing: 3 }}
        >
          FAQ's
        </Typography>

        <List>
          {faqQnA.map((data, index) => (
            <Slide
              in={true}
              direction="up"
              timeout={{ enter: index * 250 }}
              key={data.id}
            >
              <ListItem sx={{ p: 3 }}>
                <ListItemText>
                  <Typography variant="subtitle1">{data.question}</Typography>
                  <Typography>{data.answer}</Typography>
                </ListItemText>
              </ListItem>
            </Slide>
          ))}
        </List>
      </Container>
      <Footer />
    </div>
  );
};

export default FAQMorePage;
