//domestic images
import dImage1 from "../Assets/Gallery/Domestic/IMG_1805.JPG";
import dImage2 from "../Assets/Gallery/Domestic/IMG_1806.JPG";
import dImage3 from "../Assets/Gallery/Domestic/IMG_1807.JPG";
import dImage4 from "../Assets/Gallery/Domestic/IMG_1808.JPG";
import dImage5 from "../Assets/Gallery/Domestic/IMG_1809.JPG";
import dImage6 from "../Assets/Gallery/Domestic/IMG_1810.JPG";
import dImage7 from "../Assets/Gallery/Domestic/IMG_1811.JPG";
import dImage8 from "../Assets/Gallery/Domestic/IMG_1819.JPG";
import dImage9 from "../Assets/Gallery/Domestic/IMG_1820.JPG";
import dImage10 from "../Assets/Gallery/Domestic/IMG_1821.JPG";
import dImage11 from "../Assets/Gallery/Domestic/IMG_1822.JPG";
import dImage12 from "../Assets/Gallery/Domestic/IMG_1823.JPG";
//vacant images
import vImage1 from "../Assets/Gallery/Vacate/IMG_1784.JPG";
import vImage2 from "../Assets/Gallery/Vacate/IMG_1785.JPG";
import vImage3 from "../Assets/Gallery/Vacate/IMG_1786.JPG";
import vImage4 from "../Assets/Gallery/Vacate/IMG_1787.JPG";
import vImage5 from "../Assets/Gallery/Vacate/IMG_1788.JPG";
import vImage6 from "../Assets/Gallery/Vacate/IMG_1790.JPG";
import vImage7 from "../Assets/Gallery/Vacate/IMG_1791.JPG";
import vImage8 from "../Assets/Gallery/Vacate/IMG_1792.JPG";
import vImage9 from "../Assets/Gallery/Vacate/IMG_1793.JPG";
import vImage10 from "../Assets/Gallery/Vacate/IMG_1794.JPG";
import vImage11 from "../Assets/Gallery/Vacate/IMG_1796.JPG";
import vImage12 from "../Assets/Gallery/Vacate/IMG_1797.JPG";
//Commercial Images
import cImage1 from "../Assets/Gallery/Commercial/IMG_9501.JPG";
import cImage2 from "../Assets/Gallery/Commercial/IMG_9502.JPG";
import cImage3 from "../Assets/Gallery/Commercial/IMG_9503.JPG";
import cImage4 from "../Assets/Gallery/Commercial/IMG_9504.JPG";
import cImage5 from "../Assets/Gallery/Commercial/IMG_9505.JPG";
import cImage6 from "../Assets/Gallery/Commercial/IMG_9506.JPG";
import cImage7 from "../Assets/Gallery/Commercial/IMG_9507.JPG";
import cImage8 from "../Assets/Gallery/Commercial/IMG_9508.JPG";
import cImage9 from "../Assets/Gallery/Commercial/IMG_9509.JPG";
import cImage10 from "../Assets/Gallery/Commercial/IMG_9510.JPG";
import cImage11 from "../Assets/Gallery/Commercial/IMG_9511.JPG";
import cImage12 from "../Assets/Gallery/Commercial/IMG_9512.JPG";
//Steam Images
import sImage1 from "../Assets/Gallery/CarpetSteam/IMG_1779.jpg";
import sImage2 from "../Assets/Gallery/CarpetSteam/IMG_1780.jpg";
import sImage3 from "../Assets/Gallery/CarpetSteam/IMG_1781.jpg";
import sImage4 from "../Assets/Gallery/CarpetSteam/IMG_1782.jpg";
//Keeping Images
import kImage1 from "../Assets/Gallery/HouseKeeping/IMG_0001.jpeg";
import kImage2 from "../Assets/Gallery/HouseKeeping/IMG_0002.jpeg";
import kImage3 from "../Assets/Gallery/HouseKeeping/IMG_0003.jpeg";
import kImage4 from "../Assets/Gallery/HouseKeeping/IMG_0004.jpeg";
//Pressure Images
import pImage1 from "../Assets/Gallery/PressureCleaning/IMG_0001.jpeg";
import pImage2 from "../Assets/Gallery/PressureCleaning/IMG_0002.jpeg";
import pImage3 from "../Assets/Gallery/PressureCleaning/IMG_0003.jpeg";
import pImage4 from "../Assets/Gallery/PressureCleaning/IMG_0004.jpeg";
//Window Images
import wImage1 from "../Assets/Gallery/WindowCleaning/IMG_0001.jpeg";
import wImage2 from "../Assets/Gallery/WindowCleaning/IMG_0002.jpeg";
import wImage3 from "../Assets/Gallery/WindowCleaning/IMG_0003.jpeg";
import wImage4 from "../Assets/Gallery/WindowCleaning/IMG_0004.jpeg";
//Client Images
import client1 from "../Assets/Gallery/Clients/abbotts.jpg";
import client2 from "../Assets/Gallery/Clients/beaumont.jpg";
import client3 from "../Assets/Gallery/Clients/bentleigh.jpg";
import client4 from "../Assets/Gallery/Clients/bmw.jpg";
import client5 from "../Assets/Gallery/Clients/campos.jpg";
import client6 from "../Assets/Gallery/Clients/dior.jpg";
import client7 from "../Assets/Gallery/Clients/euphoria.jpg";
import client8 from "../Assets/Gallery/Clients/fibrisol.jpg";
import client9 from "../Assets/Gallery/Clients/firstpage.jpg";
import client10 from "../Assets/Gallery/Clients/guardian.jpg";
import client11 from "../Assets/Gallery/Clients/harvey.jpg";
import client12 from "../Assets/Gallery/Clients/honda.jpg";
import client13 from "../Assets/Gallery/Clients/honeycomb.jpg";
import client14 from "../Assets/Gallery/Clients/mackay.jpg";
import client15 from "../Assets/Gallery/Clients/made.jpg";
import client16 from "../Assets/Gallery/Clients/nikpol.jpg";
import client17 from "../Assets/Gallery/Clients/systems.jpg";
import client18 from "../Assets/Gallery/Clients/wool.jpg";

export const serviceImages = [
  {
    id: 1,
    image: dImage1,
    title: "Domestic",
    link: "/Services/Domestic",
  },
  {
    id: 2,
    image: vImage1,
    title: "Vacate",
    link: "/Services/Vacate",
  },
  {
    id: 3,
    image: kImage1,
    title: "House Keeping",
    link: "/Services/Vacate",
  },
  {
    id: 4,
    image: pImage2,
    title: "Pressure",
    link: "/Services/Commercial",
  },
  {
    id: 5,
    image: wImage1,
    title: "Window",
    link: "/Services/CarpetSteam",
  },
  {
    id: 6,
    image: cImage1,
    title: "Commercial",
    link: "/Services/Commercial",
  },
  {
    id: 7,
    image: sImage1,
    title: "Carpet Steam",
    link: "/Services/CarpetSteam",
  },
];

export const domesticImages = [
  {
    id: 1,
    image: dImage1,
  },
  {
    id: 2,
    image: dImage2,
  },
  {
    id: 3,
    image: dImage3,
  },
  {
    id: 4,
    image: dImage4,
  },
  {
    id: 5,
    image: dImage5,
  },
  {
    id: 6,
    image: dImage6,
  },
  {
    id: 7,
    image: dImage7,
  },
  {
    id: 8,
    image: dImage8,
  },
  {
    id: 9,
    image: dImage9,
  },
  {
    id: 10,
    image: dImage10,
  },
  {
    id: 11,
    image: dImage11,
  },
  {
    id: 12,
    image: dImage12,
  },
];

export const vacateImages = [
  {
    id: 1,
    image: vImage1,
  },
  {
    id: 2,
    image: vImage2,
  },
  {
    id: 3,
    image: vImage3,
  },
  {
    id: 4,
    image: vImage4,
  },
  {
    id: 5,
    image: vImage5,
  },
  {
    id: 6,
    image: vImage6,
  },
  {
    id: 7,
    image: vImage7,
  },
  {
    id: 8,
    image: vImage8,
  },
  {
    id: 9,
    image: vImage9,
  },
  {
    id: 10,
    image: vImage10,
  },
  {
    id: 11,
    image: vImage11,
  },
  {
    id: 12,
    image: vImage12,
  },
];

export const commercialImages = [
  {
    id: 1,
    image: cImage1,
  },
  {
    id: 2,
    image: cImage2,
  },
  {
    id: 3,
    image: cImage3,
  },
  {
    id: 4,
    image: cImage4,
  },
  {
    id: 5,
    image: cImage5,
  },
  {
    id: 6,
    image: cImage6,
  },
  {
    id: 7,
    image: cImage7,
  },
  {
    id: 8,
    image: cImage8,
  },
  {
    id: 9,
    image: cImage9,
  },
  {
    id: 10,
    image: cImage10,
  },
  {
    id: 11,
    image: cImage11,
  },
  {
    id: 12,
    image: cImage12,
  },
];

export const steamImages = [
  {
    id: 1,
    image: sImage1,
  },
  {
    id: 2,
    image: sImage2,
  },
  {
    id: 3,
    image: sImage3,
  },
  {
    id: 4,
    image: sImage4,
  },
];

export const keepingImages = [
  {
    id: 1,
    image: kImage1,
  },
  {
    id: 2,
    image: kImage2,
  },
  {
    id: 3,
    image: kImage3,
  },
  {
    id: 4,
    image: kImage4,
  },
];
export const pressureImages = [
  {
    id: 1,
    image: pImage1,
  },
  {
    id: 2,
    image: pImage2,
  },
  {
    id: 3,
    image: pImage3,
  },
  {
    id: 4,
    image: pImage4,
  },
];
export const windowImages = [
  {
    id: 1,
    image: wImage1,
  },
  {
    id: 2,
    image: wImage2,
  },
  {
    id: 3,
    image: wImage3,
  },
  {
    id: 4,
    image: wImage4,
  },
];

export const clientImages = [
  {
    id: 1,
    image: client1,
  },
  {
    id: 2,
    image: client2,
  },
  {
    id: 3,
    image: client3,
  },
  {
    id: 4,
    image: client4,
  },
  {
    id: 5,
    image: client5,
  },
  {
    id: 6,
    image: client6,
  },
  {
    id: 7,
    image: client7,
    radius: true,
  },
  {
    id: 8,
    image: client8,
  },
  {
    id: 9,
    image: client9,
    radius: true,
  },
  {
    id: 10,
    image: client10,
  },
  {
    id: 11,
    image: client11,
  },
  {
    id: 12,
    image: client12,
  },
  {
    id: 13,
    image: client13,
  },
  {
    id: 14,
    image: client14,
  },
  {
    id: 15,
    image: client15,
    radius: true,
  },
  {
    id: 16,
    image: client16,
  },
  {
    id: 17,
    image: client17,
  },
  {
    id: 18,
    image: client18,
  },
];
