import { Box, Container, Typography } from "@mui/material";
import React from "react";

const ChooseInfo = ({ icon, title, text }) => {
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Box>{icon}</Box>
      <Typography
        variant="subtitle1"
        textAlign={"center"}
        sx={{ fontWeight: 800, pt: 1, pb: 2 }}
      >
        {title}
      </Typography>
      <Typography textAlign={"center"}>{text}</Typography>
    </Container>
  );
};

export default ChooseInfo;
