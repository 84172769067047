import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceCard from "./ServiceCard";
import { serviceImages } from "../../Service/ImageData";

const Services = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        justifyContent: "center",
        display: "flex",
        pb: 5,
        backgroundColor: "#202123",
        backgroundSize: "cover",
      }}
    >
      <Grid width={"95%"} container>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ color: "#fff", paddingTop: 5, mb: 1, letterSpacing: 3 }}
          >
            Our Services
          </Typography>
        </Grid>
        {serviceImages.map((image) =>
          image.id > 2 && image.id < 6 ? (
            <Grid
              item
              md={4}
              xl={4}
              sm={12}
              xs={12}
              key={image.id}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ServiceCard
                title={image.title}
                image={image.image}
                link={image.link}
              />
            </Grid>
          ) : (
            <Grid
              item
              md={6}
              xl={6}
              sm={12}
              xs={12}
              key={image.id}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ServiceCard
                title={image.title}
                image={image.image}
                link={image.link}
              />
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );
};

export default Services;
