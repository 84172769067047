import React, { useEffect, useState } from "react";
import background1 from "../../Assets/IMG_4722.jpg";
import background2 from "../../Assets/Gallery/Domestic/IMG_1809.JPG";
import background3 from "../../Assets/Gallery/Vacate/IMG_1796.JPG";
import { useNavigate } from "react-router-dom";
import { Button, Container, Grow, Paper, Typography } from "@mui/material";

const Home = () => {
  const navigate = useNavigate();

  const backgrounds = [
    `url(${background1})`,
    `url(${background2})`,
    `url(${background3})`,
  ];

  const [currentBackground, setCurrentBackground] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackground((prev) => (prev + 1) % backgrounds.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [backgrounds.length]);

  return (
    <Container
      className="background-container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
        position: "relative", // Ensure the container is positioned relative
        zIndex: 1, // Ensure the content is on top of the overlay
        backgroundImage: backgrounds[currentBackground],
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // Cover the container completely
        backgroundPosition: "center",
      }}
      maxWidth={false}
    >
      <Container disableGutters>
        <Grow in={true}>
          <Paper
            elevation={3}
            style={{ background: "rgba(255, 255, 255, 0.5)" }}
          >
            <Container
              sx={{
                p: 5,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  p: 1,
                  letterSpacing: 1.2,
                  lineHeight: 1.2,
                  textAlign: "center",
                  mb: 2,
                }}
              >
                Best cleaners in Melbourne. If you're not satisfied, we'll clean
                again for FREE!
              </Typography>

              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: "#202123",
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingRight: 10,
                  paddingLeft: 10,
                  textTransform: "none",
                }}
                onClick={() => navigate("/Inquire")}
              >
                Enquire Now
              </Button>
            </Container>
          </Paper>
        </Grow>
      </Container>
    </Container>
  );
};

export default Home;
