import { Container, Grid, Typography } from "@mui/material";
import React from "react";
// import ChooseInfo from "./ChooseInfo";
import { clientImages } from "../../Service/ImageData";
import ClientCard from "./ClientCard";

const OurClients = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        justifyContent: "center",
        display: "flex",
        pb: 5,
      }}
    >
      <Grid
        width={"95%"}
        container
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ paddingTop: 5, mb: 1, letterSpacing: 3 }}
          >
            Our Valued Clients
          </Typography>
        </Grid>
        {clientImages.map((data) => (
          <Grid
            item
            key={data.id}
            xs={4}
            sm={2}
            md={1.3}
            lg={1.3}
            xl={1.3}
            sx={{
              p: 2,
            }}
          >
            <ClientCard image={data.image} radius={data.radius} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OurClients;
