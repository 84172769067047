import { Call, Place, Schedule } from "@mui/icons-material";

export const contactsInfo = [
  {
    id: 1,
    icon: <Call sx={{ fontSize: { xs: 80, sm: 80, md: 120 } }} />,
    title: "0401 547 599",
    description: "Mobile",
  },
  {
    id: 2,
    icon: <Place sx={{ fontSize: { xs: 80, sm: 80, md: 120 } }} />,
    title: "13B Luckins Road, Bentleigh, VIC 3204",
    description: "Address",
  },
  {
    id: 3,
    icon: <Schedule sx={{ fontSize: { xs: 80, sm: 80, md: 120 } }} />,
    title: "Open 24/7",
    description: "Work Hours",
  },
];
