import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/logo-dark.png";
import { Menu, MenuItem } from "@mui/material";
import {
  MoreItems,
  NavigationItems,
  ServicesItems,
  mobileItems,
} from "../Service/NavigationData";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const drawerWidth = 240;

const NavigationBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorElService, setAnchorElService] = React.useState(null);
  const [anchorElMore, setAnchorElMore] = React.useState(null);
  const openService = Boolean(anchorElService);
  const openMore = Boolean(anchorElMore);
  const handleService = (event) => {
    setAnchorElService(event.currentTarget);
  };

  const handleMore = (event) => {
    setAnchorElMore(event.currentTarget);
  };

  const handleSelectService = (selected) => {
    navigate(`/Services/${selected}`);
    handleCloseService();
  };

  const handleSelectMore = (selected) => {
    navigate(`/More/${selected}`);
    handleCloseMore();
  };

  const handleSelectMobile = (type, selected) => {
    if (type === "service") {
      navigate(`/Services/${selected}`);
    } else if (type === "more") {
      navigate(`/More/${selected}`);
    } else {
      navigate(selected);
    }
  };

  const handleCloseService = () => {
    setAnchorElService(null);
  };

  const handleCloseMore = () => {
    setAnchorElMore(null);
  };

  const iconRef = useRef(null);
  const [iconButtonWidth, setIconButtonWidth] = useState(0);

  useEffect(() => {
    if (iconRef.current) {
      setIconButtonWidth(iconRef.current.offsetWidth);
    }
  }, [iconRef]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        component={"img"}
        onClick={() => navigate("/Home")}
        src={logo}
        sx={{
          maxWidth: 40,
          paddingTop: 3,
          marginRight: 1,
        }}
      />
      <Typography
        variant="h4"
        component="div"
        onClick={() => navigate("/Home")}
        sx={{
          color: "#202123",
          flexGrow: 1,
          display: { fontWeight: 600 },
          padding: 2,
        }}
      >
        Clean Your Way & Co.
      </Typography>
      <Divider />
      <List>
        {mobileItems.map((item) => (
          <div key={item.id}>
            {item.title === "Services" ? (
              <Divider textAlign="left">{item.title}</Divider>
            ) : item.title === "More" ? (
              <Divider textAlign="left">{item.title}</Divider>
            ) : (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    textAlign: "left",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#202123",
                    },
                  }}
                  onClick={() => handleSelectMobile(item.type, item.link)}
                >
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{ variant: "h6" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </div>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            ref={iconRef}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { md: "none", xl: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component={"img"}
            onClick={() => navigate("/Home")}
            src={logo}
            sx={{
              maxWidth: 40,
              paddingBottom: 1,
              marginRight: 1,
              display: { xs: "none", sm: "none", md: "flex" },
              cursor: "pointer",
            }}
          />
          <Typography
            variant="h4"
            component="div"
            onClick={() => navigate("/Home")}
            sx={{
              color: "#202123",
              flexGrow: 1,
              justifyContent: {
                md: "left",
                xl: "left",
                sm: "center",
                xs: "center",
              },
              display: "flex",
              marginLeft: {
                xs: `-${iconButtonWidth}px`,
                sm: `-${iconButtonWidth}px`,
                md: 0,
                xl: 0,
              },
              cursor: "pointer",
            }}
          >
            Clean Your Way & Co.
          </Typography>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex", xl: "flex" },
              mr: 2,
            }}
          >
            {NavigationItems.map((item) => (
              <div key={item.id}>
                {item.title === "Services" && (
                  <>
                    <Button
                      key={item.id}
                      sx={{
                        color: "#202123",
                        textTransform: "none",
                        fontSize: 20,
                        position: "relative", // Make the position relative
                        "&:hover": {
                          color: "#202123", // Set text color on hover
                          fontWeight: "800", // Set a heavier font weight on hover
                          "&::after": {
                            content: "''", // Create an empty content
                            position: "absolute",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            height: "4px", // Adjust the height of the underline
                            backgroundColor: "#202123", // Color of the underline
                          },
                        },
                      }}
                      onClick={handleService}
                    >
                      {item.title}
                    </Button>
                    <Menu
                      anchorEl={anchorElService}
                      open={openService}
                      onClose={handleCloseService}
                    >
                      {ServicesItems.map((item) => (
                        <MenuItem
                          key={item.id}
                          onClick={() => handleSelectService(item.link)}
                          sx={{
                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "#202123",
                            },
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
                {item.title === "More" && (
                  <>
                    <Button
                      key={item.id}
                      sx={{
                        color: "#202123",
                        textTransform: "none",
                        fontSize: 20,
                        position: "relative", // Make the position relative
                        "&:hover": {
                          color: "#202123", // Set text color on hover
                          fontWeight: "800", // Set a heavier font weight on hover
                          "&::after": {
                            content: "''", // Create an empty content
                            position: "absolute",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            height: "4px", // Adjust the height of the underline
                            backgroundColor: "#202123", // Color of the underline
                          },
                        },
                      }}
                      onClick={handleMore}
                    >
                      {item.title}
                    </Button>
                    <Menu
                      anchorEl={anchorElMore}
                      open={openMore}
                      onClose={handleCloseMore}
                    >
                      {MoreItems.map((item) => (
                        <MenuItem
                          key={item.id}
                          onClick={() => handleSelectMore(item.link)}
                          sx={{
                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "#202123",
                            },
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
                {item.title !== "Services" && item.title !== "More" && (
                  <Button
                    key={item.id}
                    sx={{
                      color: "#202123",
                      textTransform: "none",
                      fontSize: 20,
                      position: "relative", // Make the position relative
                      "&:hover": {
                        color: "#202123", // Set text color on hover
                        fontWeight: "800", // Set a heavier font weight on hover
                        "&::after": {
                          content: "''", // Create an empty content
                          position: "absolute",
                          left: "0",
                          right: "0",
                          bottom: "0",
                          height: "4px", // Adjust the height of the underline
                          backgroundColor: "#202123", // Color of the underline
                        },
                      },
                    }}
                    onClick={() => navigate(item.title)}
                  >
                    {item.title}
                  </Button>
                )}
              </div>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Toolbar />
    </Box>
  );
};

export default NavigationBar;
