import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ title, image, link }) => {
  const navigate = useNavigate();
  return (
    <Box
      component={Button}
      fullWidth
      sx={{
        position: "relative",
        borderRadius: 0,
        textTransform: "none",
        overflow: "hidden",
        height: "30vh",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.4)", // Adjust the opacity (0.5) for dimming effect
          zIndex: 1,
        },
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding: 15,
        zIndex: 0,
        "&:hover": {
          transform: "scale(1.005)", // Increase size on hover
          "&::before": {
            background: "rgba(0, 0, 0, 0.0)", // Adjust to the original color
          },
        },
      }}
      onClick={() => navigate(link)}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.1)", // Adjust the opacity (0.5) for dimming effect
          zIndex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            p: 1,
            fontWeight: 800,
            letterSpacing: 3,
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default ServiceCard;
