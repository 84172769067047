import { Box, CircularProgress } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import logo from "../Assets/logo-dark.png";

const PageLoader = () => {
  return (
    <Container
      sx={{
        height: "95vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Stack
        direction={"column"}
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <Box
          justifyContent={"center"}
          alignSelf={"center"}
          display={"flex"}
          component={"img"}
          src={logo}
          sx={{
            margin: 5,
            width: { xs: "100px", sm: "100px", md: "200px", lg: "200px" },
          }}
        />
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </Stack>
    </Container>
  );
};

export default PageLoader;
