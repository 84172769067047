import { ImageList, ImageListItem } from "@mui/material";
import React from "react";

const ImagesList = ({ data }) => {
  return (
    <>
      <ImageList
        variant="quilted"
        cols={4}
        sx={{
          bgcolor: `#202123`,
          pl: { xs: 3, sm: 3, md: 10 },
          pr: { xs: 3, sm: 3, md: 10 },
        }}
      >
        {data.map((item) => (
          <ImageListItem key={item.image}>
            <img
              srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.image}?w=248&fit=crop&auto=format`}
              alt={item.id}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default ImagesList;
