import { Card, Container, Grow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import { commercialImages } from "../../Service/ImageData";
import background from "../../Assets/Gallery/Commercial/IMG_9501.JPG";
import DescriptionList from "../../Components/OurServices/DescriptionList";
import { commercialOffer } from "../../Service/ServicesData";
import ImagesList from "../../Components/OurServices/ImagesList";
import { useState } from "react";
import PageLoader from "../../Components/PageLoader";

const CommercialServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);

  return (
    <>
      {!pageLoaded ? (
        <PageLoader />
      ) : (
        <div
          style={{
            background: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "50vh",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              pt: 10,
              pb: 5,
              justifyContent: "center",
              display: "flex",
            }}
            disableGutters
          >
            <Grow in={true}>
              <Card
                elevation={24}
                sx={{
                  bgcolor: "#202123",
                  width: { xs: "100%", sm: "100%", md: "75%" },
                  pb: 5,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pt: 5,
                    pb: 2,
                    display: "flex",
                    color: "#fff",
                  }}
                >
                  Commercial & Industrial Cleaning
                </Typography>
                <Typography
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pr: { xs: 3, sm: 3, md: 10 },
                    pb: 7,
                    color: "#fff",
                  }}
                >
                  Maintaining a clean and hygienic work environment is paramount
                  for productivity and the well-being of your workforce. Our
                  specialized Commercial & Industrial Cleaning services are
                  tailored to meet the unique demands of businesses, ensuring a
                  safe and pristine workspace.
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, color: "#fff" }}
                >
                  What We Offer
                </Typography>
                <DescriptionList offer={commercialOffer} />
                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, pt: 5, color: "#fff" }}
                >
                  Explore Commercial Cleaning Examples
                </Typography>
                <ImagesList data={commercialImages} />
              </Card>
            </Grow>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default CommercialServicePage;
