import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ["Bebas Neue", "Quicksand", "Titillium Web"].join(","),
      h1: {
        fontSize: "5rem",
        fontFamily: "Bebas Neue",
        fontWeight: 800,
      },
      h2: {
        fontSize: "3.5rem",
        fontFamily: "Bebas Neue",
        fontWeight: 800,
      },
      h3: {
        fontSize: "2.5rem",
        fontFamily: "Titillium Web",
        fontWeight: 800,
      },
      h4: {
        fontSize: "2rem",
        fontFamily: "Bebas Neue",
        fontWeight: 800,
      },
      h5: {
        fontSize: "5rem",
        fontFamily: "Titillium Web",
        fontWeight: 800,
      },
      h6: {
        fontSize: "1rem",
        fontFamily: "Titillium Web",
        fontWeight: 800,
      },
      subtitle1: {
        fontSize: "1.5rem",
        fontFamily: "Titillium Web",
        fontWeight: 800,
      },
      subtitle2: {
        fontSize: "1rem",
        fontFamily: "Titillium Web",
        fontWeight: 800,
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "Quicksand",
      },
      body2: {
        fontSize: "0.5rem",
        fontFamily: "Quicksand",
      },
      button: {
        fontSize: "1rem",
        fontFamily: "Quicksand",
        fontWeight: 800,
      },
    },
    palette: {
      background: {
        default: "#ffffff", //green
      },
      primary: {
        main: "#202123", //indigo
      },
      secondary: {
        main: "#ffffff", //pink
      },
      text: {
        primary: "#202123", //black
        secondary: "#FFFFFF", //white
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: "#da0000",
            color: "#FFFFFF", // Set your desired text color
            fontSize: "1rem",
            fontFamily: "Quicksand",
          },
          arrow: {
            color: "#da0000", // Set the color of the arrow
          },
        },
      },
    },
    // TextField: {
    //   styleOverrides: {
    //     root: {
    //       background: "#ffffff",
    //       color: "#202123",
    //     },
    //   },
    // },
  })
);

export default theme;
