import { Card, Container, Grow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import { vacateImages } from "../../Service/ImageData";
import background from "../../Assets/Gallery/Vacate/IMG_1787.JPG";
import DescriptionList from "../../Components/OurServices/DescriptionList";
import ImagesList from "../../Components/OurServices/ImagesList";
import { vacateOffer } from "../../Service/ServicesData";
import { useState } from "react";
import PageLoader from "../../Components/PageLoader";

const VacateServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);
  return (
    <>
      {!pageLoaded ? (
        <PageLoader />
      ) : (
        <div
          style={{
            background: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "50vh",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              pt: 10,
              pb: 5,
              justifyContent: "center",
              display: "flex",
            }}
            disableGutters
          >
            <Grow in={true}>
              <Card
                elevation={24}
                sx={{
                  bgcolor: "#202123",
                  width: { xs: "100%", sm: "100%", md: "75%" },
                  pb: 5,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pt: 5,
                    pb: 2,
                    display: "flex",
                    color: "#fff",
                  }}
                >
                  End of Lease & Pre-Sale Cleaning
                </Typography>
                <Typography
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pr: { xs: 3, sm: 3, md: 10 },
                    pb: 7,
                    color: "#fff",
                  }}
                >
                  Preparing to move out or list your property for sale? Our
                  specialized End of Lease and Pre-Sale cleaning services are
                  designed to leave your space immaculate, ensuring it's ready
                  for the next occupant or potential buyers.
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, color: "#fff" }}
                >
                  What We Offer
                </Typography>
                <DescriptionList offer={vacateOffer} />
                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, pt: 5, color: "#fff" }}
                >
                  Explore Vacate Cleaning Examples
                </Typography>
                <ImagesList data={vacateImages} />
              </Card>
            </Grow>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default VacateServicePage;
