import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { quickItems, quickServiceItems } from "../../Service/NavigationData";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#202123",
        backgroundSize: "cover",
        justifyContent: "center",
        display: "flex",
        padding: 2,
      }}
    >
      <Grid
        width={"95%"}
        container
        rowSpacing={{ sm: 0, xs: 0, md: 3, lg: 3 }}
        columnSpacing={3}
      >
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ffffff",
              fontWeight: 800,
              letterSpacing: 2,
            }}
          >
            About us
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#fff",
              textAlign: "left",
              letterSpacing: 1,
            }}
          >
            At Clean Your Way & Co., we understand the importance of customer
            satisfaction. That’s why we are committed to providing the highest
            quality services with attention to detail that is second to none. We
            go above and beyond to ensure that every job is completed to the
            highest of standards.
          </Typography>
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ffffff",
              fontWeight: 800,
              mt: { xs: 2, sm: 2 },
              ml: { md: 5 },
              pl: { xs: 0, sm: 0, md: 1 },
              letterSpacing: 2,
            }}
          >
            Quick Links
          </Typography>
          {quickItems.map((item) => (
            <Typography
              key={item.id}
              variant="h6"
              sx={{
                color: "#fff",
                textAlign: "left",
                letterSpacing: 1,
                ml: { md: 5 },
                cursor: "pointer",
                "&:hover": {
                  color: "#202123",
                  backgroundColor: "#fff",
                },
                pl: 1,
              }}
              onClick={() => navigate(item.link)}
            >
              {item.title}
            </Typography>
          ))}
        </Grid>
        <Grid item md={2.5} sm={12} xs={12}>
          <Typography
            display={{ sm: "none", xs: "none", md: "flex", lg: "flex" }}
            variant="subtitle1"
            sx={{
              color: "#202123",
              fontWeight: 800,
              pl: 1,
              letterSpacing: 2,
              visibility: "hidden",
            }}
          >
            Services
          </Typography>

          {quickServiceItems.map((item) =>
            item.id < 5 ? (
              <Typography
                key={item.id}
                variant="h6"
                sx={{
                  color: "#fff",
                  textAlign: "left",
                  letterSpacing: 1,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#202123",
                    backgroundColor: "#fff",
                  },
                  pl: 1,
                }}
                onClick={() => navigate(item.link)}
              >
                {item.title}
              </Typography>
            ) : (
              <span key={item.id}></span>
            )
          )}
        </Grid>
        <Grid item md={1.5} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#202123",
              fontWeight: 800,
              pl: 1,
              letterSpacing: 2,
              visibility: "hidden",
            }}
            display={{ sm: "none", xs: "none", md: "flex", lg: "flex" }}
          >
            Links
          </Typography>

          {quickServiceItems.map((item) =>
            item.id > 4 ? (
              <Typography
                key={item.id}
                variant="h6"
                sx={{
                  color: "#fff",
                  textAlign: "left",
                  letterSpacing: 1,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#202123",
                    backgroundColor: "#fff",
                  },
                  pl: 1,
                }}
                onClick={() => navigate(item.link)}
              >
                {item.title}
              </Typography>
            ) : (
              <span key={item.id}></span>
            )
          )}
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography variant="body1" sx={{ color: "#fff", mt: 2 }}>
            © 2023 Clean Your Way And Co.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
