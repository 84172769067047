import React, { useEffect } from "react";
import { keepingImages } from "../../Service/ImageData";
import { keepingOffer } from "../../Service/ServicesData";
import { Card, Container, Grow, Typography } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import background from "../../Assets/Gallery/HouseKeeping/IMG_0001.jpeg";
import ImagesList from "../../Components/OurServices/ImagesList";
import DescriptionList from "../../Components/OurServices/DescriptionList";
import { useState } from "react";
import PageLoader from "../../Components/PageLoader";

const HouseKeepingServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);
  return (
    <>
      {!pageLoaded ? (
        <PageLoader />
      ) : (
        <div
          style={{
            background: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "50vh",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              pt: 10,
              pb: 5,
              justifyContent: "center",
              display: "flex",
            }}
            disableGutters
          >
            <Grow in={true}>
              <Card
                elevation={24}
                sx={{
                  bgcolor: "#202123",
                  width: { xs: "100%", sm: "100%", md: "75%" },
                  pb: 5,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pt: 5,
                    pb: 2,
                    display: "flex",
                    color: "#fff",
                  }}
                >
                  House Keeping
                </Typography>
                <Typography
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pr: { xs: 3, sm: 3, md: 10 },
                    pb: 7,
                    color: "#fff",
                  }}
                >
                  A clean and organized home is the foundation of a peaceful and
                  comfortable life. Our dedicated team of housekeepers is
                  committed to providing top-notch housekeeping services
                  tailored to your specific needs.
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, color: "#fff" }}
                >
                  What We Offer
                </Typography>
                <DescriptionList offer={keepingOffer} />
                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, pt: 5, color: "#fff" }}
                >
                  Explore House Keeping Examples
                </Typography>
                <ImagesList data={keepingImages} />
              </Card>
            </Grow>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default HouseKeepingServicePage;
