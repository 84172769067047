import NavigationBar from "./Components/NavigationBar";
import HomePage from "./Pages/HomePage";
import InquirePage from "./Pages/InquirePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import DomesticServicePage from "./Pages/ServicesPages/DomesticServicePage";
import VacateServicePage from "./Pages/ServicesPages/VacateServicePage";
import CommercialServicePage from "./Pages/ServicesPages/CommercialServicePage";

import CarpetSteamServicePage from "./Pages/ServicesPages/CarpetSteamServicePage";
import ContactsMorePage from "./Pages/MorePages/ContactsMorePage";
import EmploymentMorePage from "./Pages/MorePages/EmploymentMorePage";
import FAQMorePage from "./Pages/MorePages/FAQMorePage";
import theme from "./Styles/theme";
import HouseKeepingServicePage from "./Pages/ServicesPages/HouseKeepingServicePage";
import PressureCleaningServicePage from "./Pages/ServicesPages/PressureCleaningServicePage";
import WindowCleaningServicePage from "./Pages/ServicesPages/WindowCleaningServicePage";
import NotFoundPage from "./Pages/NotFoundPage";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/Inquire" element={<InquirePage />} />
          <Route path="/Services/Domestic" element={<DomesticServicePage />} />
          <Route path="/Services/Vacate" element={<VacateServicePage />} />
          <Route
            path="/Services/Commercial"
            element={<CommercialServicePage />}
          />
          <Route
            path="/Services/CarpetSteam"
            element={<CarpetSteamServicePage />}
          />
          <Route
            path="/Services/HouseKeeping"
            element={<HouseKeepingServicePage />}
          />
          <Route
            path="/Services/Pressure"
            element={<PressureCleaningServicePage />}
          />
          <Route
            path="/Services/Window"
            element={<WindowCleaningServicePage />}
          />
          <Route path="/More/Contacts" element={<ContactsMorePage />} />
          <Route path="/More/Employment" element={<EmploymentMorePage />} />
          <Route path="/More/FAQ" element={<FAQMorePage />} />

          <Route path="/NotFound" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/NotFound" />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
