import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Grow,
  InputAdornment,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import Joi from "joi";
import { Email, Person, Phone, Place } from "@mui/icons-material";
import { ServicesItems } from "../../Service/NavigationData";
import emailjs from "@emailjs/browser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InquireForm = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    name: "",
    email: "",
    contactNo: "",
    address: "",
    service: "default",
    instructions: "",
  });

  const schema = Joi.object({
    name: Joi.string().max(255).required(),
    email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required(),
    contactNo: Joi.string()
      .pattern(/^4\d{8}$/)
      .required()
      .messages({
        "string.pattern.base":
          "Invalid phone number. Please enter a valid phone number starting with 4",
      }),
    address: Joi.string().max(255).required(),
    service: Joi.string().max(255),
    instructions: Joi.string().max(255).required(),
  });

  const handleChange = ({ currentTarget: input }) => {
    setForm({
      ...form,
      [input.name]: input.value,
    });

    const { error } = schema
      .extract(input.name)
      .label(input.name)
      .validate(input.value);

    if (error) {
      if (
        error.details[0].message === `"${input.name}" must be a valid email`
      ) {
        setErrors({
          ...errors,
          [input.name]:
            "Invalid email format. Please provide a valid email address.",
        });
      } else if (
        error.details[0].message ===
        `"${input.name}" is not allowed to be empty`
      ) {
        switch (input.name) {
          case "name":
            setErrors({
              ...errors,
              [input.name]: "Please input your Name",
            });
            break;
          case "contactNo":
            setErrors({
              ...errors,
              [input.name]: "Please input your Contact Number",
            });
            break;

          case "address":
            setErrors({
              ...errors,
              [input.name]: "Please input your Address",
            });
            break;
          case "service":
            setErrors({
              ...errors,
              [input.name]: "Please enter subject",
            });
            break;
          case "instructions":
            setErrors({
              ...errors,
              [input.name]: "Please enter other details",
            });
            break;
          case "email":
            setErrors({ ...errors, [input.name]: "Please input your email" });
            break;
          default:
            setErrors({ ...errors, [input.name]: error.details[0].message });
        }
      } else {
        setErrors({ ...errors, [input.name]: error.details[0].message });
      }
    } else {
      delete errors[input.name];
      setErrors(errors);
    }
  };

  const isFormInvalid = () => {
    const result = schema.validate(form);
    return !!result.error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_y42mrn8",
      "template_ijds3ie",
      e.target,
      "I370WysBwOSF789oO"
    );
    setOpen(true);
    setForm({
      name: "",
      email: "",
      contactNo: "",
      address: "",
      service: "default",
      instructions: "",
    });
  };

  return (
    <>
      <Grow in={true}>
        <Card
          sx={{
            m: 1,
            padding: 5,
            borderRadius: 3,
            bgcolor: "#202123",
            width: { xs: "100%", sm: "100%", md: "70%" },
          }}
        >
          <form className="contact_form" onSubmit={(e) => handleSubmit(e)}>
            <Grid
              container
              columns={12}
              rowSpacing={{ xs: 1, sm: 1, md: 3 }}
              columnSpacing={3}
            >
              <Grid item md={12}>
                <Typography
                  textAlign={"center"}
                  variant="h3"
                  sx={{ color: "#fff" }}
                >
                  Tell Us About Your Cleaning Needs
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Name
                </Typography>
                <Tooltip
                  title={errors.name}
                  open={!!errors.name}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <TextField
                    variant="outlined"
                    name="name"
                    error={errors.name}
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    value={form.name}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person sx={{ mr: 1 }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="John Doe"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Email
                </Typography>
                <Tooltip
                  title={errors.email}
                  open={!!errors.email}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <TextField
                    name="email"
                    error={!!errors.email}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    value={form.email}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email sx={{ mr: 1 }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="john@example.com"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Contact Number
                </Typography>
                <Tooltip
                  title={errors.contactNo}
                  open={!!errors.contactNo}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="contactNo"
                    error={!!errors.contactNo}
                    value={form.contactNo}
                    onChange={handleChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone sx={{ mr: 1 }} />
                          +61
                        </InputAdornment>
                      ),
                    }}
                    placeholder="444 444 444"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Address
                </Typography>
                <Tooltip
                  title={errors.address}
                  open={!!errors.address}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="address"
                    error={!!errors.address}
                    value={form.address}
                    onChange={handleChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Place sx={{ mr: 1 }} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="123 Main Street, City, State, ZIP"
                  />
                </Tooltip>
              </Grid>
              {/* </Grid> */}
              <Grid item md={12}>
                <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                  Message
                </Typography>
              </Grid>
              {/* <Grid container columns={12} spacing={3}> */}
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Subject/Type of Service
                </Typography>
                <Tooltip
                  title={errors.service}
                  open={!!errors.service}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!errors.service}
                  >
                    <Select
                      name="service"
                      value={form.service}
                      onChange={(service) =>
                        setForm({
                          ...form,
                          // eslint-disable-next-line
                          ["service"]: service.target.value,
                        })
                      }
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "30%", lg: "30%" },
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <MenuItem value="default" disabled>
                        Select type of service
                      </MenuItem>

                      {ServicesItems.map((item, index) => (
                        <MenuItem key={index} value={item.title}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant={"subtitle2"} sx={{ color: "#fff", pl: 1 }}>
                  Other Information/Instructions
                </Typography>
                <Tooltip
                  title={errors.instructions}
                  open={!!errors.instructions}
                  placement="top-end"
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <TextField
                    variant="outlined"
                    multiline
                    rows={5}
                    fullWidth
                    name="instructions"
                    error={!!errors.instructions}
                    value={form.instructions}
                    onChange={handleChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                    placeholder="Please provide any additional details or specific requests here."
                  />
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    width: { xs: "50%", sm: "50%", md: "20%" },
                    padding: { xs: 1, sm: 1, md: 2 },
                    mt: 2,
                    bgcolor: "#ffd95d",
                    color: "#202123",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#edb809",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#ccc",
                      color: "#999", // Customize the text color for disabled state
                    },
                  }}
                  // onClick={handleSubmit}
                  disabled={isFormInvalid()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
          >
            <DialogTitle variant="subtitle1">Email Sent</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "#202123", textAlign: "center" }}>
                Your inquiry has been submitted. Our team will get back to you
                shortly. Thank you for choosing Clean Your Way & Co..
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={handleClose}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Grow>
    </>
  );
};

export default InquireForm;
