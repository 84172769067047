import React, { useEffect } from "react";
import Home from "../Components/HomeComponent/Home";
import WhyChoose from "../Components/WhyChooseUs/WhyChoose";
import Services from "../Components/OurServices/Services";
import Footer from "../Components/Footer/Footer";
import OurClients from "../Components/OurClients/OurClients";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Home />
      <WhyChoose />
      <Services />
      <OurClients />
      {/* <FAQ /> */}
      <Footer />
    </>
  );
};

export default HomePage;
