import React from "react";
import background from "../Assets/Gallery/Presale/IMG_9565.jpg";
import { Container } from "@mui/material";
import InquireForm from "../Components/InquiryForm/InquireForm";
import { useState } from "react";
import { useEffect } from "react";
import PageLoader from "../Components/PageLoader";

const InquirePage = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);
  return (
    <>
      {!pageLoaded ? (
        <PageLoader />
      ) : (
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: { md: "95vh" },
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <InquireForm />
        </Container>
      )}
    </>
  );
};

export default InquirePage;
