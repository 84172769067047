import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import ChooseInfo from "./ChooseInfo";
import { whyChooses } from "../../Service/WhyChooseData";

const WhyChoose = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        justifyContent: "center",
        display: "flex",
        pb: 5,
      }}
    >
      <Grid width={"95%"} container>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ paddingTop: 5, mb: 1, letterSpacing: 3 }}
          >
            Why Choose Us?
          </Typography>
        </Grid>
        {whyChooses.map((data) => (
          <Grid
            item
            key={data.id}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
            sx={{
              p: 2,
            }}
          >
            <ChooseInfo
              icon={data.icon}
              title={data.title}
              text={data.description}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WhyChoose;
