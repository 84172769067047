import { Box } from "@mui/material";
import React from "react";

const ClientCard = ({ image, radius }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: 5,
        backgroundSize: "contain", // Adjust for smaller screens
      }}
    />
  );
};

export default ClientCard;
