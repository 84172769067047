export const NavigationItems = [
  { id: 1, title: "Home" },
  { id: 2, title: "Services" },
  { id: 3, title: "More" },
];

export const ServicesItems = [
  { id: 1, title: "Regular Domestic Clean", link: "Domestic" },
  { id: 2, title: "End Of Lease & Pre-Sale Clean", link: "Vacate" },
  { id: 3, title: "Commercial & Industrial Clean", link: "Commercial" },
  { id: 4, title: "Carpet Deep & Steam Clean", link: "CarpetSteam" },
  { id: 5, title: "House Keeping", link: "HouseKeeping" },
  { id: 6, title: "Pressure Cleaning", link: "Pressure" },
  { id: 7, title: "Window Cleaning", link: "Window" },
];

export const MoreItems = [
  { id: 1, title: "Contacts", link: "Contacts" },
  // { id: 2, title: "Employment", link: "Employment" },
  { id: 2, title: "FAQ's", link: "FAQ" },
];

export const mobileItems = [
  { id: 1, title: "Home", link: "Home", type: " " },
  { id: 2, title: "Services", link: "Services" },
  { id: 3, title: "Regular Domestic Clean", link: "Domestic", type: "service" },
  {
    id: 4,
    title: "End Of Lease & Pre-Sale Clean",
    link: "Vacate",
    type: "service",
  },

  {
    id: 5,
    title: "Commercial & Industrial Clean",
    link: "Commercial",
    type: "service",
  },
  {
    id: 6,
    title: "Carpet Deep & Steam Clean",
    link: "CarpetSteam",
    type: "service",
  },
  {
    id: 7,
    title: "House Keeping",
    link: "HouseKeeping",
    type: "service",
  },
  {
    id: 8,
    title: "Pressure Cleaning",
    link: "Pressure",
    type: "service",
  },
  {
    id: 9,
    title: "Window Cleaning",
    link: "Window",
    type: "service",
  },
  { id: 10, title: "More", link: "More" },
  { id: 11, title: "Contacts", link: "Contacts", type: "more" },
  // { id: 9, title: "Employment", link: "Employment", type: "more" },
  { id: 12, title: "FAQ's", link: "FAQ", type: "more" },
];

export const quickItems = [
  { id: 1, title: "Home", link: "/Home" },
  { id: 2, title: "Enquire", link: "/Inquire" },
  { id: 3, title: "FAQ's", link: "/More/FAQ" },
  // { id: 3, title: "Employment", link: "/More/Employment" },
  { id: 4, title: "Contacts", link: "/More/Contacts" },
];
export const quickServiceItems = [
  { id: 1, title: "Regular Domestic Clean", link: "/Services/Domestic" },
  { id: 2, title: "End Of Lease & Pre-Sale Clean", link: "/Services/Vacate" },
  {
    id: 3,
    title: "Commercial & Industrial Clean",
    link: "/Services/Commercial",
  },
  { id: 4, title: "Carpet Deep & Steam Clean", link: "/Services/CarpetSteam" },
  { id: 5, title: "House Keeping", link: "/Services/HouseKeeping" },
  { id: 6, title: "Pressure Cleaning", link: "/Services/Pressure" },
  { id: 7, title: "Window Cleaning", link: "/Services/Window" },
];
