import { Card, Container, Grow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import { steamImages } from "../../Service/ImageData";
import background from "../../Assets/Gallery/CarpetSteam/IMG_1779.jpg";
import DescriptionList from "../../Components/OurServices/DescriptionList";
import ImagesList from "../../Components/OurServices/ImagesList";
import { steamOffer } from "../../Service/ServicesData";
import PageLoader from "../../Components/PageLoader";
import { useState } from "react";

const CarpetSteamServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);

  return (
    <>
      {!pageLoaded ? (
        <PageLoader />
      ) : (
        <div
          style={{
            background: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "50vh",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              pt: 10,
              pb: 5,
              justifyContent: "center",
              display: "flex",
            }}
            disableGutters
          >
            <Grow in={true}>
              <Card
                elevation={24}
                sx={{
                  bgcolor: "#202123",
                  width: { xs: "100%", sm: "100%", md: "75%" },
                  pb: 5,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pt: 5,
                    pb: 2,
                    display: "flex",
                    color: "#fff",
                  }}
                >
                  Carpet Deep & Steam Cleaning
                </Typography>
                <Typography
                  sx={{
                    pl: { xs: 3, sm: 3, md: 10 },
                    pr: { xs: 3, sm: 3, md: 10 },
                    pb: 7,
                    color: "#fff",
                  }}
                >
                  Revitalize your carpets with our expert Carpet Deep & Steam
                  Cleaning services. Our specialized techniques go beyond
                  surface cleaning, ensuring a thorough and rejuvenated look for
                  your carpets, while also promoting a healthier indoor
                  environment.
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, color: "#fff" }}
                >
                  What We Offer
                </Typography>
                <DescriptionList offer={steamOffer} />
                <Typography
                  variant={"subtitle1"}
                  sx={{ pl: { xs: 3, sm: 3, md: 10 }, pt: 5, color: "#fff" }}
                >
                  Explore Steam Cleaning Examples
                </Typography>
                <ImagesList data={steamImages} />
              </Card>
            </Grow>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default CarpetSteamServicePage;
