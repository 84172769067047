export const faqQnA = [
  {
    id: 1,
    question: "What types of cleaning services do you offer?",
    answer:
      "We offer regular/once off domestic cleaning, commercial and industrial cleaning, carpet deep steam cleaning, end of lease and pre sale cleans, window cleaning and housekeeping services.",
  },
  {
    id: 2,
    question: "Are your cleaning products safe for pets and children?",
    answer:
      "Yes, we prioritize the use of eco-friendly and non-toxic cleaning products. They are safe for both your family and pets while still effectively cleaning and disinfecting your space.",
  },
  {
    id: 3,
    question:
      "How do you ensure the safety of my property and belongings during cleaning?",
    answer:
      "Our cleaning professionals are thoroughly vetted, trained, and insured. We also have strict protocols in place to ensure the security of your property and belongings while we work.our cleaners are also covered for any accidents that may occur.",
  },
  {
    id: 4,
    question: "What areas do you serve?",
    answer:
      "We proudly serve Metro Melbourne and Greater Melbourne and the surrounding areas. Please contact us to confirm if we operate in your specific location.",
  },
  {
    id: 5,
    question: "How do I schedule a cleaning service?",
    answer:
      "You can book your cleaning by sending thru your enquiry. We usually do a quick visit to the site and give you a no obligation quote for free.",
  },
];
