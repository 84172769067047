import { List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const DescriptionList = ({ offer }) => {
  return (
    <List>
      {offer.map((data, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText>
            <Typography
              sx={{
                pl: { xs: 3, sm: 3, md: 10 },
                pb: 2,
                pr: { xs: 3, sm: 3, md: 10 },
                color: "#fff",
              }}
            >
              <span style={{ fontWeight: 700, letterSpacing: 1 }}>
                {data.title}
              </span>
              : {data.description}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DescriptionList;
