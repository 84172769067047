import {
  InsertEmoticon,
  Security,
  Verified,
  WorkspacePremium,
} from "@mui/icons-material";

export const whyChooses = [
  {
    id: 1,
    icon: <WorkspacePremium sx={{ fontSize: { xs: 100, sm: 100, md: 150 } }} />,
    title: "Trusted & Experienced",
    description:
      "We are a Melbourne based company, with over a decade of experience in the cleaning industry. With the excellence we instil in our company practices, we have built long-lasting relationships with businesses and families just like yours.",
  },
  {
    id: 2,
    icon: <Security sx={{ fontSize: { xs: 100, sm: 100, md: 150 } }} />,
    title: "Fully Insured & Police Checks",
    description:
      "We prioritize your peace of mind. Our cleaning service is fully insured, providing comprehensive coverage for any unexpected incidents. Additionally, every member of our team undergoes rigorous vetting, including thorough police checks, ensuring that you can trust the individuals entering your space.",
  },
  {
    id: 3,
    icon: <InsertEmoticon sx={{ fontSize: { xs: 100, sm: 100, md: 150 } }} />,
    title: "100% Satisfaction Guarantee",
    description:
      "We're dedicated to delivering excellence. If you ever feel that something may have been overlooked or doesn't meet your standards, notify us, and we'll return within 24 hours to address the issue, ensuring your complete satisfaction.",
  },
  {
    id: 4,
    icon: <Verified sx={{ fontSize: { xs: 100, sm: 100, md: 150 } }} />,
    title: "Reliable Expert Service",
    description:
      "Reliable Expert Service is not just a promise, it's our guarantee. Our team is fully insured, and each member undergoes meticulous background checks, including police verifications. With us, you can expect top-notch expertise and unwavering dependability.",
  },
];
