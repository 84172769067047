import { Container, Grid, Slide, Typography } from "@mui/material";
import React from "react";
import Footer from "../../Components/Footer/Footer";
import ChooseInfo from "../../Components/WhyChooseUs/ChooseInfo";
import { contactsInfo } from "../../Service/ContactsData";

const ContactsMorePage = () => {
  return (
    <div
    // style={{
    //   background: `#202123`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "center",
    //   height: "30vh",
    // }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          justifyContent: "center",
          display: "flex",
          pb: 5,
        }}
      >
        <Grid width={"95%"} minHeight={"70vh"} container>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              sx={{ paddingTop: 5, mb: 1, letterSpacing: 3 }}
            >
              Contacts
            </Typography>
          </Grid>
          {contactsInfo.map((data, index) => (
            <Slide in={true} direction="up" key={data.id} timeout={250}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  p: 2,
                }}
              >
                <ChooseInfo
                  icon={data.icon}
                  title={data.title}
                  text={data.description}
                />
              </Grid>
            </Slide>
          ))}
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactsMorePage;
