import { Button, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";
import logo from "../Assets/err.png";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Container
      sx={{
        height: "95vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <Box
          justifyContent={"center"}
          alignSelf={"center"}
          display={"flex"}
          component={"img"}
          src={logo}
          sx={{
            width: { xs: "150px", sm: "150px", md: "250px", lg: "250px" },
          }}
        />
        <Typography variant="h1">Sorry</Typography>
        <Typography variant="h3">We couldn't find that page</Typography>
        <Button
          variant="contained"
          sx={{
            mt: 2,
            bgcolor: "#202123",
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 5,
            paddingLeft: 5,
            textTransform: "none",
          }}
          onClick={() => navigate("/Home")}
        >
          Go back to Home
        </Button>
      </Stack>
    </Container>
  );
};

export default NotFoundPage;
