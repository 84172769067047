export const domesticOffer = [
  {
    title: "Thorough Cleaning",
    description:
      "From dusting and vacuuming to mopping and sanitizing, we leave no corner untouched. Our meticulous approach ensures every surface is sparkling clean.",
  },
  {
    title: "Customized Service",
    description:
      "We understand that every home is unique. That's why we offer customizable cleaning plans, allowing you to prioritize specific areas or tasks according to your preferences.",
  },
  {
    title: "Trained and Trustworthy Staff",
    description:
      "Our professional cleaners are extensively trained, background-checked, and committed to upholding the highest standards of cleanliness and professionalism.",
  },
  {
    title: "Environmentally Friendly Products",
    description:
      "We prioritize your health and the environment. Our cleaning products are eco-friendly, ensuring a safe and sustainable cleaning experience.",
  },
  {
    title: "Flexible Scheduling",
    description:
      "Whether you need a one-time deep clean or regular maintenance, we work around your schedule to provide convenient and reliable cleaning services.",
  },
];
export const vacateOffer = [
  {
    title: "Thorough Deep Cleaning",
    description:
      "Our experienced team goes above and beyond, tackling every nook and cranny to bring your property to its best possible condition.",
  },
  {
    title: "Attention to Detail",
    description:
      "We understand the importance of presentation. We meticulously clean and sanitize every surface, ensuring a fresh and inviting atmosphere.",
  },
  {
    title: "Exterior Cleaning Services",
    description:
      "In addition to interior cleaning, we also offer exterior services such as pressure washing, window cleaning, and garden maintenance to enhance curb appeal.",
  },
  {
    title: "Customized Cleaning Packages",
    description:
      "We offer flexible cleaning packages tailored to your specific needs, whether it's a comprehensive end of lease clean or a pre-sale touch-up.",
  },
  {
    title: "Compliance with Real Estate Standards",
    description:
      "Our services are aligned with the cleaning standards set by real estate agencies, ensuring that your property meets the necessary criteria.",
  },
];
export const commercialOffer = [
  {
    title: "Comprehensive Cleaning Expertise",
    description:
      "Our skilled team is equipped to handle a wide range of commercial and industrial spaces, including offices, warehouses, manufacturing facilities, and more.",
  },
  {
    title: "Specialized Equipment",
    description:
      "We utilize state-of-the-art cleaning equipment and techniques to tackle the specific cleaning needs of industrial and commercial environments.",
  },
  {
    title: "Safety and Compliance",
    description:
      "We strictly adhere to industry safety standards and regulations to provide a clean and hazard-free work environment for your employees.",
  },
  {
    title: "Customized Cleaning Plans",
    description:
      "We work closely with each client to create tailored cleaning plans that address their unique requirements and scheduling preferences.",
  },
  {
    title: "Eco-Friendly Practices",
    description:
      "Our commitment to environmental responsibility means we use eco-friendly cleaning products and practices whenever possible.",
  },
];
export const steamOffer = [
  {
    title: "Deep Cleaning Expertise",
    description:
      "Our trained technicians use advanced equipment and effective cleaning solutions to penetrate deep into the fibers of your carpets, removing embedded dirt, stains, and allergens.",
  },
  {
    title: "Steam Cleaning for Sanitization",
    description:
      "Our steam cleaning process not only cleans but also sanitizes your carpets, killing bacteria, germs, and dust mites, resulting in a fresher and healthier living space.",
  },
  {
    title: "Stain Removal",
    description:
      "We tackle tough stains and spots with precision, applying targeted treatments to lift and eliminate even the most stubborn marks.",
  },
  {
    title: "Quick Drying Process",
    description:
      "Our efficient drying methods ensure minimal downtime, allowing you to enjoy your freshly cleaned carpets sooner.",
  },
  {
    title: "Safe and Eco-Friendly Solutions",
    description:
      "We prioritize the use of environmentally-friendly cleaning products that are safe for both your family and pets.",
  },
];

export const keepingOffer = [
  {
    title: "Comprehensive Cleaning",
    description:
      "From dusting and vacuuming to laundry and organization, our housekeepers take care of all the essential tasks needed to maintain a spotless home.",
  },
  {
    title: "Detail-Oriented Approach",
    description:
      "We pay close attention to the small details, ensuring that every corner of your home is thoroughly cleaned and tidied.",
  },
  {
    title: "Customizable Service Plans",
    description:
      "We understand that every home is unique. That's why we offer flexible service plans that can be customized to fit your specific requirements and schedule.",
  },
  {
    title: "Trained and Trustworthy Staff",
    description:
      "Our housekeepers are carefully vetted, trained, and equipped with the skills and knowledge to provide exceptional service. You can trust them to respect your space and belongings.",
  },
  {
    title: "Green Cleaning Solutions",
    description:
      "We use eco-friendly cleaning products and practices to promote a healthy living environment for you and your family.",
  },
];
export const pressureOffer = [
  {
    title: "High-Pressure Cleaning",
    description:
      "Our trained technicians utilize high-pressure water jets to remove dirt, grime, mold, and other stubborn stains from various surfaces including driveways, sidewalks, decks, and more.",
  },
  {
    title: "Gentle Cleaning for Delicate Surfaces",
    description:
      "We use appropriate pressure levels and nozzles to ensure safe cleaning of delicate surfaces like siding, windows, and decorative elements.",
  },
  {
    title: "Stain and Graffiti Removal",
    description:
      "Our pressure cleaning services include targeted treatments to tackle tough stains, as well as graffiti removal to restore the integrity of your property.",
  },
  {
    title: "Preventative Maintenance",
    description:
      "Regular pressure cleaning helps prevent long-term damage and extends the lifespan of your property's surfaces, saving you money on costly repairs in the future.",
  },
  {
    title: "Environmentally-Friendly Practices",
    description:
      "We use eco-friendly cleaning solutions and adhere to environmentally responsible practices to minimize our impact on the environment.",
  },
];
export const windowOffer = [
  {
    title: "Interior and Exterior Window Cleaning",
    description:
      "Our skilled team ensures a thorough clean for both the inside and outside of your windows, removing dirt, grime, and water spots.",
  },
  {
    title: "High-Quality Cleaning Solutions",
    description:
      "We use top-quality, environmentally-friendly cleaning solutions to ensure safe and effective cleaning for all types of windows, including glass, frames, and sills.",
  },
  {
    title: "Screen Cleaning",
    description:
      "We don't just stop at the glass. We also clean window screens to ensure that your entire window system looks its best.",
  },
  {
    title: "Hard-to-Reach Windows",
    description:
      "Our technicians are equipped with the necessary tools and equipment to safely clean even the highest or most difficult-to-access windows.",
  },
  {
    title: "Regular Maintenance Plans",
    description:
      "We offer customizable cleaning schedules to keep your windows looking their best year-round.",
  },
];
